/**
 * Reveal Modals - ajax content loading.
 * General object for managing ajax-loaded content in Reveal modals.
 *
 * @package	FlyTyer2019
 */

( function ( $ ) {
	var ajaxModals = {
		settings: {
			wpApi: window.location.protocol + '//' + window.location.hostname +
				'/wp-json/wp/v2/'
		},

		/**
		 * Initialize Modals.
		 * This is currently limited to cards for related or featured flies, but
		 * coul be extended if needed.
		 *
		 * @return {null}
		 */
		init: function () {
			// Community flies.
			$( document ).on(
				'click',
				'#flies .card--flies', {
					context: this
				},
				function ( e ) {
					var $card = $( e.currentTarget ),
						modal = '#' + $card.data( 'modal' ),
						url   = e.data.context.settings.wpApi + $card.data( 'post-type' ) +
						"/" + $card.data( 'post-id' );

					// Run the ajax query.
					e.data.context.getContents( modal, url, e );
					e.preventDefault();
				}
			);
		},

		/**
		 * Ajax content loading.
		 * On user request, load the post-level content for the requested item
		 * into the modal. Reveal no longer offers this as a default options so
		 * we have to roll our own.
		 *
		 * @param  {string} modal Selector ID.
		 * @param  {string} url   Permalink for the requested content.
		 * @param  {object} e     The event that triggered the request.
		 * @return {null}
		 */
		getContents: function ( modal, url, e ) {
			var $modal    = $( modal ),
				$fly      = $modal.find( '.reveal-contents .teaser-fly' ),
				$pattern  = $modal.find( '.reveal-contents .fly-pattern' ),
				$cards    = $( '.card--flies' ),
				current   = $cards.index( e.currentTarget ),
				previous  = $cards[ current - 1 ],
				next      = $cards[ current + 1 ],
				$prev_nav = $modal.find( '.modal-nav .modal-nav-prev' ),
				$next_nav = $modal.find( '.modal-nav .modal-nav-next' );

			$.get(
				url,
				function ( resp ) {
					// Oh yay! Dates.
					var d           = new Date( resp.date ),
						displayDate = pad( d.getMonth() + 1 ) + "/" + pad( d.getDate() ) +
						"/" + d.getFullYear(),
						figure      = resp.post_image;

					if ( resp.video_embed != '' ) {
						figure = resp.video_embed;
						figure = figure.replace( 'data-card-theme="light"',
						'data-card-theme="dark" data-card-width="100%"' );
					}
					$fly.find( '.fly__figure' ).html( figure );
					$fly.find( '.fly__caption .content' ).html(
						'<h3>' + resp.title.rendered +
						'</h3>'
					).append( resp.post_meta.fly_description );
					$fly.find( '.fly__caption .post-meta' ).html(
						'<div>' + displayDate +
						', shared by <span class="fly__submitter">' + resp.post_meta.fly_name +
						' from ' + resp.post_meta
						.fly_city +
						'</span></div>'
					);

					if ( resp.fly_pattern.post_image != '' ) {

						$pattern.find( '.pattern__figure' ).html( resp.fly_pattern.post_image );
						$pattern.find( '.pattern__caption .content' ).html(
							'<h4>' +
								resp.fly_pattern.post_title + '</h4>'
						)
							.append( resp.fly_pattern.post_excerpt );
						$pattern.find( '.pattern__caption .link' ).html(
							'<a href="' + window.location.origin + '/' +
							resp.fly_pattern.post_name + '" aria-label="View ' +
							resp.fly_pattern.post_title + '" class="excerpt-read-more">View more</a>'
						);
					} else {
						$pattern.find( '.pattern__figure' ).html( ' ' );
						$pattern.find( '.pattern__caption .content' ).html( ' ' );
						$pattern.find( '.pattern__caption .link' ).html( ' ' );
						$pattern.find( '.pattern__caption .content' ).html( '<p>Fly Tyer pattern tutorials were not used for this fly.</p>' );
					}

					// Set up our next/previous navigation based on the current card.
					e.data.context.initModalNav( previous, e.currentTarget, next, modal );

					// TODO: Change the active item in the slick carousel outside the modal.
					if ( $modal.not( ':visible' ).length ) {
						$modal.foundation( 'open' );
					}

				}
			);

			/**
			 * Utility function - date formatting
			 *
			 * @param  {integer} n date part
			 * @return {integer}   date part with leading zeros, if needed.
			 */
			function pad( n ) {
				return n < 10 ? '0' + n : n;
			}
		},

		/**
		 * Show and hide modal navigation.
		 * When is a carousel not really a carousel?
		 * When it's an ajax loaded modal.
		 * This shows/hides navigation based on the existence of another
		 * card element in the queue.
		 *
		 * @param  {object} object  Card in this position.
		 * @param  {string} navItem Selector for related navigation.
		 * @return {null}
		 */
		setNavClasses: function ( object, navItem ) {
			switch ( object ) {
				case undefined:
					$( navItem ).addClass( 'modal-nav-disabled' );
					break;
				default:
					$( navItem ).removeClass( 'modal-nav-disabled' );
			}
		},

		/**
		 * Modal navigation setup.
		 * Attach the appropriate previous/next items to the navigation arrows.
		 *
		 * @param  {string}   prev    Selector for the previous item in the list.
		 * @param  {string}   current Selector for the current item in the list.
		 * @param  {string} next    Selector for the next item in the list.
		 * @param  {string}   modal   Selector for the modal.
		 * @return {null}
		 */
		initModalNav: function ( prev, current, next, modal ) {
			var prev_post = $( prev ).data( 'post-id' ),
				next_post = $( next ).data( 'post-id' ),
				$prev_nav = $( '.modal-nav .modal-nav-prev' ),
				$next_nav = $( '.modal-nav .modal-nav-next' );

			this.setNavClasses( prev_post, $prev_nav );
			this.setNavClasses( next_post, $next_nav );

			$prev_nav.data( 'post-id', prev_post );
			$next_nav.data( 'post-id', next_post );

			$( document ).on(
				'click keydown',
				'.modal-nav li', {
					'prev_post': prev_post,
					'next_post': next_post,
					'current_post': $( current ).data( 'post-id' )
				},
				function ( e ) {
					if ( e.type == 'click' || e.which == 13 ) {

						var $prev = $( prev ),
							$next = $( next );

						switch ( $( e.currentTarget ).data( 'post-id' ) ) {
							case e.data.prev_post:
								$prev.trigger( 'click' );
								break;
							case e.data.next_post:
								$next.trigger( 'click' );
								break;
							default:
								return false;
						}

						e.stopPropagation();
					}
				}
			);
		}
	}
	// Ready... set... go!! DOM initialization.
	$( document ).ready( ajaxModals.init() );

} )( jQuery );
