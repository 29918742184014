/**
 * Slick Carousel Management.
 * General object for configuring and initializing all
 * Slick Carousels on the site.
 *
 * @package	FlyTyer2019
 */

( function ( $ ) {
	var ftSlick = {
		// configuration container.
		carousels: {
			tutorial: {
				selector: '.slick--tutorial',
				options: {
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: true,
					speed: 500
				}
			},
			tutorials: {
				selector: '.slick--tutorials',
				options: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: false,
					mobileFirst: true,
					responsive: [ {
						breakpoint: 1024
					},
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 2
							}
					},
						{
							breakpoint: 480,
							settings: {
								slidesToShow: 1
							}
					}
					]
				}
			},
			home: {
				selector: '.cards--mobile-slick',
				options: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: false,
					swipe: true,
					centerMode: true,
					centerPadding: '20px',
					mobileFirst: true,
					respondTo: 'window',
					responsive: [ {
						breakpoint: 300,
					},
						{
							breakpoint: 640,
							settings: 'unslick',
					},
					]
				}
			},
			patterns: {
				selector: '.slick--flies',
				options: {
					slidesToShow: 5,
					slidesToScroll: 1,
					infinite: true,
					swipe: false,
					responsive: [ {
						breakpoint: 1024,
						settings: {
							slidesToShow: 3
						}
					},
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 2
							}
					},
						{
							breakpoint: 480,
							settings: {
								slidesToShow: 1
							}
					}
					]
				}
			}
		},

		/**
		 * Master Initilization
		 * Any time you create a new carousel, create a configuration method
		 * and add it here.
		 *
		 * @return {null}
		 */
		init: function () {

			var keys   = Object.keys( this.carousels ),
				keylen = keys.length;
			for ( var i = 0; i < keylen; i++ ) {
				var key       = keys[ i ],
					instance  = this.carousels[ key ],
					$instance = $( instance.selector );

				// Initialize the carousel. Not sure if the iterator is necessary...
				// but just in case, as some of these selectors return multiple objects.
				var that = this;
				$instance.each(
					function ( index, element ) {
						var $instance = $( this ),
							limit     = $instance.find( '.card' ).length;

						if ( 'tutorials' == key ) {
							// we're getting strange behaviour if there are
							// fewer than the designated # of items in the carousel.
							if ( limit < instance.options.slidesToShow ) {
								instance.options.slidesToShow = limit;
								instance.options.responsive   = [ {
									breakpoint: 640
								}, {
									breakpoint: 480,
									settings: {
										slidesToShow: 1
									}
								} ];
							}
						}

						$instance.slick( instance.options );
						that.thumbnailNav( $instance );

						// Listen for custom Slick events.
						$( this ).on(
							'reInit setPosition unslick',
							function ( e ) {
								e.stopPropagation();
							}
						);

						if ( 'tutorial' !== key ) {
							$( this ).on(
								'breakpoint',
								function ( e, slick, breakpoint ) {
									$( e.currentTarget ).removeClass( 'slick-mobile' );

									if ( ( '640' == breakpoint ) || ( '480' == breakpoint ) ) {
										$( e.currentTarget ).addClass( 'slick-mobile' );
									}
									e.stopPropagation();
								}
							)
						}

					}
				);

			}

			// Set up modal events.
			this.modalEvents();
		},

		/**
		 * Initilaize Modal-Contained Carousels
		 * Tutorials work differently due to the Reveal modal that houses them.
		 *
		 * @return {null}
		 */
		modalEvents: function () {
			$( '.reveal--gallery' ).on(
				'open.zf.reveal',
				{
					context: this
				},
				function ( e ) {
					var $instance = $( e.currentTarget ).find( '.slick' );

					setTimeout(
						function () {
							$instance.slick( 'setPosition' );
						},
						100,
						$instance
					);
				}
			);
			$( document ).on(
				'closed.zf.reveal',
				'.reveal--gallery',
				{
					context: this
				},
				function ( e ) {}
			);

		},

		/**
		 * Thumbnail navigation setup.
		 * Some carousels have thumbnail navigation that isn't using slick itself,
		 * so we need some smoke and mirrors.
		 *
		 * @param  {object} $instance jQuery object that represents the carousel.
		 * @return {boolean}           Just returning out if the function isn't applicable.
		 */
		thumbnailNav: function ( $instance ) {
			var $thumbs = $instance.next( '.slick--thumbs' ).find(
				'div.slick-thumb'
			);
			if ( $thumbs.length == 0 ) {
				return;
			};

			$( '.slick-active img' ).focus();
			$thumbs.first().addClass( 'current-thumb' );

			$thumbs.on(
				'click',
				{
					'$instance': $instance,
					'$thumbs': $thumbs
				},
				function ( e ) {
					var index = $( this ).find( 'a' ).attr( "data-slide" );
					e.data.$thumbs.removeClass( 'current-thumb' );
					$( this ).addClass( 'current-thumb' );
					e.data.$instance.slick( 'slickGoTo', index, false );
					e.stopPropagation();
				}
			);

			$instance.on(
				'beforeChange',
				{
					'$thumbs': $thumbs
				},
				function ( e, slick, currentSlide,
					nextSlide ) {
					e.data.$thumbs.removeClass( 'current-thumb' );
					e.data.$thumbs.find( 'a' ).filter( '[data-slide=' + nextSlide + ']' ).parent(
						'div.slick-thumb'
					).addClass( 'current-thumb' );
				}
			);

			$instance.parents( '.reveal--gallery' ).on(
				'closed.zf.reveal',
				{
					'$instance': $instance
				},
				function ( e ) {
					e.data.$instance.slick( 'slickGoTo', 0, false );
				}
			);
		}
	};

	// Ready... set... go!! DOM initialization.
	$( document ).ready( ftSlick.init() );

} )( jQuery );
