/**
 * Custom Fly Tyer JS.
 *
 * @package	FlyTyer2019
 */

// TODO: Break this into partials. The GULP file is setup to concatenated all JS files into one.
jQuery(document).ready(
	function () {


		jQuery('.card--flies .card__link').click(
			function () {
				jQuery('.close-button').focus();
			}
		)

		/**
		 * Smooth Scrolling.
		 */

		jQuery('a[href*=#]:not([href=#])').click(
			function () {
				var location = window.location
				if (location.pathname.replace(/^\//, '') == this.pathname.replace(
					/^\//,
					''
				) && location.hostname == this.hostname) {
					var target = jQuery(this.hash);
					target = target.length ? target : jQuery(
						'[name=' + this.hash.slice(1) +
						']'
					);
					if (target.length) {
						jQuery('html,body').animate({
							scrollTop: target.offset().top - 350
						},
							700
						);
						return false;
					}
				}
			}
		);

		// Form video canvas.
		jQuery('.acf-oembed').click(
			function () {
				jQuery('.canvas').addClass('canvas--reveal');
			}
		)

		// Stick the Newsletter Box in the sidebar.
		jQuery(window).scroll(
			function () {

				if (jQuery('.newsletter__wrap').length) {
					var scroll = jQuery(window).scrollTop();

					var content_os = jQuery('.newsletter__box').offset().top;
					var content_ht = jQuery('.newsletter__box').height();

					var wrap_os = jQuery('.sidebar__wrap').offset().top;
					var wrap_ht = jQuery('.sidebar__wrap').height();

					var unstuck_os = jQuery('.sidebar').offset().top;
					var unstuck_ht = jQuery('.sidebar').height();

					if (jQuery('.main__wrap').height() > jQuery('.sidebar__wrap').height()) {

						if (scroll > wrap_ht + 50) {
							jQuery('.newsletter__wrap').addClass('stuck');
							jQuery('.newsletter__wrap').removeClass('unstuck');
							// Remove the class on the parent wrapper.
							jQuery('.sidebar').removeClass('has-unstuck');
						}

						if (scroll < wrap_ht + 50) {
							jQuery('.newsletter__wrap').removeClass('stuck');
							jQuery('.newsletter__wrap').removeClass('unstuck');
							// Remove the class on the parent wrapper.
							jQuery('.sidebar').removeClass('has-unstuck');
						}

						if (scroll > unstuck_ht - content_ht - 40) {
							jQuery('.newsletter__wrap').addClass('unstuck');
							jQuery('.newsletter__wrap').removeClass('stuck');

							// Adding a class to the parent wrapper so we can prevent an overlap on short sidebars.
							jQuery('.sidebar').addClass('has-unstuck');
						}

						if (scroll < unstuck_ht - content_ht - 40) {
							jQuery('.newsletter__wrap').removeClass('unstuck');
							// Remove the class on the parent wrapper.
							jQuery('.sidebar').removeClass('has-unstuck');
						}
					}

				}

			}
		);

		/**
		 * Photo field for community upload form.
		 */
		jQuery('.flies-photo .acf-label').find('label').html(
			'No photo selected <span class="acf-required">*</span>'
		);
		jQuery('.flies-photo input').on(
			'change',
			function () {

				if (jQuery(this).val()) {
					jQuery('.flies-photo .acf-label').addClass('has-file');
					jQuery('.flies-photo .acf-label').find('label').html(
						'<span class="upload-status">Photo uploaded </span>'
					);

				} else {
					jQuery('.flies-photo .acf-label').removeClass('has-file');
					jQuery('.flies-photo .acf-label').find('label').html(
						'<span class="upload-status">No photo selected </span><span class="acf-required">*</span>'
					);
				}
			}
		);

		jQuery('.acf-fields').addClass('row');
		jQuery('.acf-field--post-title').addClass('large-6 column');
		jQuery('.flies-photo input[type=file]')
			.attr('id', 'flies-photo')
			.insertAfter('.flies-photo .acf-basic-uploader')
			.prev()
			.addClass('button')
			.attr('tabindex', '0')
			.attr('for', 'flies-photo')
			.text('Select Photo');

		jQuery(document).on(
			'keydown',
			'.flies-photo .acf-basic-uploader',
			function (e) {
				if (e.which == 13) {
					jQuery(this).next('#flies-photo').trigger('click');
				}
			}
		);
		/**
		 * Drawer Management.
		 */
		jQuery(".side-nav").on(
			"on.zf.toggler",
			function (e) {
				jQuery('html').addClass('no-scroll');
				jQuery('html').addClass('no-scroll drawer-scrim');
				jQuery('body').bind(
					'touchmove',
					function (e) {
						e.preventDefault()
					}
				);
				jQuery(document).mouseup(
					function (e) {
						var container = jQuery(".side-nav");
						var modal = jQuery(".reveal");
						var modalClose = jQuery(".close-button");
						var navTrigger = ('#logo-nav-toggle');
						//  If the target of the click isn't the container 
						//  nor a descendant of the container, toggle off.
						if (!jQuery(container, modal, modalClose).find(e.target) &&
							container.has(
								e.target
							).length === 0) {
							jQuery(container).foundation('toggle');
							jQuery(navTrigger).foundation('toggle');
						}
					}
				);
			}
		);

		jQuery(".side-nav").on(
			"off.zf.toggler",
			function (e) {
				jQuery('html').removeClass('no-scroll');
				jQuery('html').removeClass('drawer-scrim');
				jQuery('body').unbind('touchmove');
				jQuery(document).unbind("mouseup");
			}
		);

	}
);

/**
 * Equal hight Recent Posts and News on the Homepage.
 * Clone the guts of the cards if we are above 640px. This allows us to use CSS grids for equal heights.
 */
(function ($) {
	var ftAppend = {
		vars: {
			parent: $('.cards--advanced'),
			selector: {
				posts: $('#recent-posts'),
				news: $('#recent-news')
			},
			heading: {
				posts: $('#heading-recent-posts'),
				news: $('#heading-recent-news')
			}
		},

		on: function () {
			var that = this;
			$(window).on(
				'load resize orientationchange',
				function() {
					throttleFunction(adjustCardHeight,500)		
			});
		},
			

		off: function () {
			// TODO: Kill the ON, once it has fired.
		},

		init: function () {
			this.on();
			// TODO: Add the this.off().
		}
	};

	var  timerId;
// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
var throttleFunction = function (func, delay) {
	// If setTimeout is already scheduled, no need to do anything
	if (timerId) {
		return;
	}

	// Schedule a setTimeout after delay seconds
	timerId = setTimeout(function () {
		func();

		// Once setTimeout function execution is finished, timerId = undefined so that in <br>
		// the next scroll event function execution can be scheduled by the setTimeout
		timerId = undefined;
	}, delay)
}

/* */
function adjustCardHeight() {
	/* Initializes a on load resize or orientationchange */
	if ($(window).width() > 640) {
		if (!that.vars.parent.hasClass('cards--equal-height')) {
			$(that.vars.selector.posts).find('.card').clone(true, true).insertAfter(
				$(that.vars.heading.posts)
			);
			$(that.vars.selector.news).find('.card').clone(true, true).insertAfter(
				$(that.vars.heading.news)
			);
			that.vars.parent.addClass('cards--equal-height');
		}

		if (that.vars.parent.hasClass('cards--equal-height')) {
			// Do Nothing. This really is not needed.
		}
	}
}

	// Append to your hearts content.
	$(document).ready(ftAppend.init());
} ) (jQuery);


// Start animating on scroll.
AOS.init({
	once: true,
	offset: 0,
	// TODO: probably only fire this on the homepage.
});
