/**
 * Search Placeholder.
 * General object for changing the placeholder text on the homepage.
 *
 * @package	FlyTyer2019
 */

( function ( $ ) {
	var ftPlaceholder = {
		vars: {
			inputField: $( '.search--homepage input' ),
		},
		init: function() {
			var that = this;
			$( window ).on(
				'load resize orientationchange',
				function() {
					throttleFunction(adjustAttrs,5000);
				}
			);
		}
	}

	var  timerId;
	// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
var throttleFunction = function (func, delay) {
	// If setTimeout is already scheduled, no need to do anything
	if (timerId) {
		return;
	}

	// Schedule a setTimeout after delay seconds
	timerId = setTimeout(function () {
		func();

		// Once setTimeout function execution is finished, timerId = undefined so that in <br>
		// the next scroll event function execution can be scheduled by the setTimeout
		timerId = undefined;
	}, delay)
}

function adjustAttrs() {

		/* Initializes a on load resize or orientationchange */
		if ( $( window ).width() > 1400 ) {
			that.vars.inputField.attr( 'placeholder', 'Search Patterns, Tutorials, and Stories...' );
		} else if ( $( window ).width() > 1060 ) {
			that.vars.inputField.attr( 'placeholder', 'Search Patterns and more...' );
		} else {
			that.vars.inputField.attr( 'placeholder', 'Search...' );
		}
	
}
	// Ready... set... go!! DOM initialization.
	$( document ).ready( ftPlaceholder.init() );

} )( jQuery );
